import React from "react";
import { BrowserRouter as Router, Redirect, Link } from "react-router-dom";
function Footer() {
  return (
    <footer>
      <div style={{ marginTop: "10px", borderTop: "1px solid #ff8c00" }}>
        <div className="row footer-content">
          <div className="col-4 offset-1 footer-content-left">
            <img
              src={process.env.PUBLIC_URL + '/bee_logo.png'}
              style={{ width: "60px" }}
            ></img>
            <span style={{ fontSize: "1em", marginTop: "5px", marginLeft: "25px" }}>
              {" "}
               © 2021 - All rights reserved - 
               <a style={{fontSize:"1em"}} className="link-tally" href="#">Privacy Policy</a>
               &nbsp;and <a style={{fontSize:"1em"}} className="link-tally" href="#">Terms</a>
            </span>
          </div>
          <div className="col-2">
            <p className="tally-p" style={{ fontSize: "0.9em" }}>Follow us: <a href="#">Facebook</a>, <a href="#">Instagram</a></p>
          </div>
          <div className="col-3  offset-1 footer-content-right">
            <span>
              <Link to="/dashboard" className="link-a">Home</Link>{" "}
              <Link to="/view-tag" className="link-a">Prodotti</Link>{" "}
              <Link to="/support" className="link-a">User Guide</Link>{" "}
              <Link to="/support" className="link-a">Help</Link>
              <span style={{ fontWeight: "bold", fontSize: "0.8em" }}><i>V 1.0.3</i></span>
            </span>
          </div>
        </div>
      </div>
    </footer >
  );
}
export default Footer;
