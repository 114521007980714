import React from "react";

function Collapse(props) {

    return (
        <div>
            <span style={{ borderBottom: "1px solid black", margin: "0 auto", width: "95%" }} className="ticket" data-toggle="collapse" href={"#" + props.id} role="button" aria-expanded="false" aria-controls="collapseExample">
                {props.nome} <img style={{width:"10px",float: "right",paddingBottom:"15px"}} src="https://dashboard.bee-id.it/immaginiComuni/right.svg"></img>
            </span>
            <div className="collapse collapse-support" id={props.id}>
                <div className="card card-body collapse-support content-collapse-support">
                    <div style={{ margin: "0 auto", marginBottom: "13px", width: "95%" }}>
                        <div>
                            {props.contenuto}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default Collapse;