import React from "react";
import common from "../Component/common.js";
import Loading from "../Component/Loading.js";
import PreviewTemplate from "../Component/PreviewTemplate.js";
import SceltaCategoria from "../Component/ChooseTemplate/SceltaCategoria.js";
;
class ChooseTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      sceltaTemplate: [false, false, false, false, false, true],
      immaginiTemplate: [
        ["https://dashboard.bee-id.it/immaginiComuni/pasta.png"],
        ["https://dashboard.bee-id.it/immaginiComuni/templateVino.png"],
        ["https://dashboard.bee-id.it/immaginiComuni/birra.png"],
        ["https://dashboard.bee-id.it/immaginiComuni/integratori.png"],
        ['https://dashboard.bee-id.it/immaginiComuni/template8.png'],
        ['https://dashboard.bee-id.it/immaginiComuni/template-generico.jpg'],
        ['https://dashboard.bee-id.it/immaginiComuni/template10/apiua.png'],
        ['https://dashboard.bee-id.it/immaginiComuni/template13/co-turism.png'],
        ['https://dashboard.bee-id.it/immaginiComuni/template11/senza-foto.png'],
        ['https://dashboard.bee-id.it/immaginiComuni/template11/menu-digitale.png'],
        ['https://dashboard.bee-id.it/immaginiComuni/template-15.png']
      ],
      immaginescelta: 5
    };
    this.cambiaValore = this.cambiaValore.bind(this);
  }
  async componentDidMount() {
    document.title = "Scelta Template - Bee Dashboard";
    common.checkTagAzienda(this.props.match.params.idTag, this);
    this.setState({ loaded: true });

  }
  cambiaValore(event) {
    common.cambiaValoreTesto(this, event);
  }
  cambiaStile(numero) {
    let appoggio = this.state.sceltaTemplate;
    appoggio.fill(false);
    appoggio[numero] = true;
    this.setState({ sceltaTemplate: appoggio, immaginescelta: numero });
  }
  render() {
    if (this.state.loaded !== true || this.state.errorLogin) {
      return (
        <Loading errorLogin={this.state.errorLogin}></Loading>
      );
    }
    return (
      <React.Fragment>

        <div className="row" style={{ marginTop: "40px" }}>
          <div className="col-12 offset-1">
            <span style={{ fontSize: "1.2em", color: "#BEBEBE", fontWeight: "300", textDecoration: "none" }}>
              Nuovo Progetto&nbsp;&gt;&nbsp;<span style={{ fontSize: "1em" }} className="titolo-pagina"> Scegli lo stile grafico </span>&nbsp;&gt;&nbsp; Modifica i contenuti</span>
          </div>
        </div>
        <div className="row page-tally" style={{ marginTop: "5px" }}>
          {false && <div className="col-2 offset-1">
            <ul className="listaChooseTemplate">
              {true && <SceltaCategoria numero={5} scelta={this.state.sceltaTemplate} nome="Ristorazione" cambiaStile={(e) => { this.cambiaStile(e) }}></SceltaCategoria>}
              {true && <SceltaCategoria numero={0} scelta={this.state.sceltaTemplate} nome="Food & Beverage" cambiaStile={(e) => { this.cambiaStile(e) }}></SceltaCategoria>}
              {false && <SceltaCategoria numero={3} scelta={this.state.sceltaTemplate} nome="Bevande" cambiaStile={(e) => { this.cambiaStile(e) }}></SceltaCategoria>}
              {true && <SceltaCategoria numero={4} scelta={this.state.sceltaTemplate} nome="Pharma" cambiaStile={(e) => { this.cambiaStile(e) }}></SceltaCategoria>}
              {<SceltaCategoria numero={1} scelta={this.state.sceltaTemplate} nome="Vino" cambiaStile={(e) => { this.cambiaStile(e) }}></SceltaCategoria>}
              {true && <SceltaCategoria numero={6} scelta={this.state.sceltaTemplate} nome="Servizi" cambiaStile={(e) => { this.cambiaStile(e) }}></SceltaCategoria>}
              {/*Vida*/}
              {((this.state.azienda && this.state.azienda.EMAIL === "vidarte@virgilio.it") || (this.state.azienda && this.state.azienda.EMAIL === "jacopo@slumdesign.com")) &&
                <SceltaCategoria numero={2} scelta={this.state.sceltaTemplate} nome="Vida" cambiaStile={(e) => { this.cambiaStile(e) }}></SceltaCategoria>
              }
            </ul>
          </div>}
          <div className="col-10 offset-1">
            {this.state.sceltaTemplate[5] && (
              <div className="row row-template">
                <PreviewTemplate titlepreview="BEE-CUSTOM"
                  caratteristiche={
                    <ul>
                      <li>- Aggiungere un prodotto, la descrizione e la foto</li>
                      <li>- Aggiungere gli altri prodotti in una gallery con dei link che indirizzano al vostro sito web</li>
                      <li>- Aggiungere un codice sconto per ordini sul vostro ecommerce</li>
                      <li>- Aggiungere il profilo aziendale completo con foto e descrizione</li>
                      <li>- Collegamenti social: Facebook, Instagram e YouTube</li>
                    </ul>}
                  descrizione="Vi consigliamo questo modello nel caso vogliate essere liberi di creare il vostro modello in completa autonomia e in più lingue, potete scegliere anche il colore dello sfondo e dei testi.
Adatto per presentare non solo un singolo prodotto ma proporre anche una gallery di tutti i vostri prodotti con collegamenti esterni (ad esempio al vostro catalogo prodotti o ecommerce). Perfetto da utilizzare per chi vuole descrivere anche l’azienda e le persone. Offre inoltre la possibilità di utilizzare un codice sconto per ordinare sul vostro ecommerce" idTemplate="19" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[6]}></PreviewTemplate>

                <PreviewTemplate caratteristiche={
                  <ul>
                    <li>- Aggiungere un prodotto, la descrizione e la foto</li>
                    <li>- Aggiungere gli altri prodotti in una gallery con dei link che indirizzano al vostro sito web</li>
                    <li>- Aggiungere un codice sconto per ordini sul vostro ecommerce</li>
                    <li>- Aggiungere il profilo aziendale completo con foto e descrizione</li>
                    <li>- Collegamenti social: Facebook, Instagram e YouTube</li>
                  </ul>}
                  descrizione="Questo è il modello perfetto per la consegna a domicilio, potrai creare il tuo negozio virtuale e ricevere direttamente gli ordini sul tuo numero Whats App o su quello della tua azienda."
                  titlepreview="BEE-ORDER" idTemplate="15" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[10]}></PreviewTemplate>

                <PreviewTemplate caratteristiche={
                  <div>
                    <ul>
                      <li>- Aggiungere prodotti e suddividerli per categorie.</li>
                      <li>- Aggiungere le fotografie dei prodotti.</li>
                      <li>- Aggiungere il prezzo dei prodotti.</li>
                      <li>- Aggiungere una descrizione del prodotto.</li>
                      <li>- Ricevere gli ordini sul tuo numero Whats App.</li>
                      <li>- Collegamenti social: Facebook e Instagram</li>
                    </ul>
                  </div>}
                  descrizione="Questo modello su sfondo scuro è molto elegante e propone una slide gallery con cui potete presentare subito tutti i vostri prodotti.
                  E’ perfetto per la consegna a domicilio, potrai creare il tuo negozio virtuale e ricevere direttamente gli ordini sul tuo numero Whats App o su quello della tua azienda."
                  titlepreview="BEE-MENU" idTemplate="12" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[9]}></PreviewTemplate>

                <PreviewTemplate caratteristiche={
                  <div>
                    <span>
                      Questo modello è adatto ad una utenza internazionale avendo la possibilità di caricare tutti i contenuti nelle lingue che desiderate.
                      E’ perfetto per la consegna a domicilio, potrai infatti creare il tuo negozio virtuale e ricevere direttamente gli ordini sul tuo numero Whats App o su quello della tua azienda.
                </span>
                    <ul>
                      <li>- Aggiungere prodotti e suddividerli per categorie.</li>
                      <li>- Aggiungere le fotografie dei prodotti.</li>
                      <li>- Aggiungere il prezzo dei prodotti.</li>
                      <li>- Aggiungere una descrizione del prodotto.</li>
                      <li>- Ricevere gli ordini sul tuo numero Whats App.</li>
                    </ul>
                  </div>
                }
                  descrizione="Questo è il modello perfetto per la consegna a domicilio, potrai creare il tuo negozio virtuale e ricevere direttamente gli ordini sul tuo numero Whats App o su quello della tua azienda."
                  titlepreview="BEE-SHOP " idTemplate="11" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[this.state.immaginescelta]}></PreviewTemplate>


                <PreviewTemplate caratteristiche={
                  <div>
                    <ul>
                      <li> Aggiungere un prodotto e la descrizione.</li>
                      <li> Aggiungere la fotografia del prodotto.</li>
                      <li> Aggiungere la ricetta abbinata al prodotto ed i vari step.</li>
                      <li> Aggiungere la possibilità di inserire la mail e ricevere delle info aggiuntive.</li>
                      <li> Aggiungere il profilo aziendale con foto e descrizione</li>
                      <li> Aggiungere un link che indirizzi al sito web ufficiale</li>
                      <li> Inviare mail diretta</li>
                      <li> Collegamenti social: Facebook</li>
                    </ul>
                  </div>
                } descrizione="
                Vi consigliamo questo modello nel caso vogliate dare uno stile elegante, contemporaneo e qualitativo al vostro prodotto. Perfetto da utilizzare per chi vuole proporre abbinamenti con ricette; offre inoltre la possibilità di inserire la propria mail per ricevere degli aggiornamenti."
                  titlepreview="BEE-LIGHT" idTemplate="1" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[0]}></PreviewTemplate>



                <PreviewTemplate titlepreview="BEE-DYNAMIC"
                  caratteristiche={<div>
                    <ul>
                      <li> Aggiungere un prodotto e la descrizione.</li>
                      <li> Aggiungere la fotografia del prodotto.</li>
                      <li> Aggiungere la ricetta abbinata al prodotto.</li>
                      <li> Aggiungere la possibilità di inserire la mail per ricevere la newsletter ed eventualmente ricevere uno sconto.</li>
                      <li> Aggiungere il profilo aziendale o altro con foto e descrizione</li>
                      <li> Aggiungere un link che indirizzi al sito web ufficiale</li>
                      <li> Inviare mail diretta</li>
                      <li> Collegamenti social: Facebook</li>
                    </ul>
                  </div>}
                  descrizione="Vi consigliamo questo modello nel caso vogliate dare uno stile elegante e minimal al vostro prodotto aggiungendo il collegamento ad un video. Perfetto da utilizzare per chi vuole proporre abbinamenti con ricette; offre inoltre la possibilità di inserire la propria mail per ricevere degli aggiornamenti." idTemplate="7" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[2]}></PreviewTemplate>


                <PreviewTemplate titlepreview="BEE-ELEGANT"
                  caratteristiche={
                    <div>
                      <ul>
                        <li> Aggiungere un prodotto e la descrizione.</li>
                        <li> Aggiungere la fotografia del prodotto.</li>
                        <li> Aggiungere un link ad un video</li>
                        <li> Aggiungere note, abbinamenti e scheda</li>
                        <li> Aggiungere il profilo aziendale completo con foto e descrizione</li>
                        <li> Aggiungere un link che indirizzi all’ecommerce</li>
                        <li> Collegamenti social: Facebook e Instagram</li>
                      </ul>
                    </div>
                  }
                  descrizione="Vi consigliamo questo modello nel caso vogliate raccontare un singolo prodotto in modo completo aggiungendo anche il collegamento ad un video. Perfetto da utilizzare per chi vuole descrivere non solo il prodotto, ma anche l’azienda e le persone. Offre inoltre la possibilità di fare un richiesta per tour di degustazione e di inserire un link al vostro ecommerce." idTemplate="8" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[4]} />

                <PreviewTemplate titlepreview="BEE-EMOTION"
                  caratteristiche={
                    <div>
                      <ul>
                        <li> Aggiungere un prodotto, la descrizione, foto, note e abbinamenti.</li>
                        <li> Aggiungere la mail per tour di degustazione.</li>
                        <li> Aggiungere breve profilo aziendale con foto e descrizione</li>
                        <li> Inviare mail diretta</li>
                        <li> Collegamenti social: Facebook</li>
                      </ul>
                    </div>
                  }
                  descrizione="Vi consigliamo questo modello nel caso vogliate presentare un solo prodotto corredato di video in background.
Perfetto da utilizzare per chi vuole descrivere brevemente anche l’azienda e le persone. Offre inoltre la possibilità di fare una richiesta per tour di degustazione." idTemplate="2" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[1]}></PreviewTemplate>

                <PreviewTemplate
                  caratteristiche={
                    <div>
                      <ul>
                        <li> Aggiungere un prodotto e la descrizione.</li>
                        <li> Aggiungere la fotografia del prodotto.</li>
                        <li> Aggiungere un link alla scheda tecnica</li>
                        <li> Inviare mail diretta</li>
                        <li> Collegamenti social: Facebook</li>
                      </ul>
                    </div>
                  }
                  titlepreview="BEE-GREY" descrizione="Vi consigliamo questa grafica nel caso vogliate dare uno stile elegante, contemporaneo e qualitativo al vostro prodotto. Caratteristiche principali." idTemplate="6" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[3]}></PreviewTemplate>


                <PreviewTemplate titlepreview="BEE-TOURISM"
                  caratteristiche={
                    <div>
                      <ul>
                        <li> Aggiungere prodotti/servizi e suddividerli per categorie</li>
                        <li> Aggiungere le fotografie dei prodotti.</li>
                        <li> Aggiungere una descrizione del prodotto.</li>
                        <li> Aggiungere link esterni per i singoli prodotti/servizi</li>
                        <li> Inviare mail diretta</li>
                        <li> Collegamenti social: Facebook</li>
                      </ul>
                    </div>
                  }
                  descrizione="Vi consigliamo questa grafica nel caso vogliate creare una webapp adatta a raggruppare diversi servizi/prodotti che hanno bisogno di una descrizione articolata."
                  idTemplate="13" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[7]}></PreviewTemplate>
              </div>
            )}
            {/*Template Custom*/}
            {/*Template Vida*/}
            {this.state.sceltaTemplate[2] && (
              <div className="row row-template">
                <PreviewTemplate titlepreview="Template Quadro" descrizione="Template del singolo quadro Vidà" idTemplate="3" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[1]}></PreviewTemplate>
                <PreviewTemplate titlepreview="Template Sezione" descrizione="Template delle varie sezioni Vidà" idTemplate="4" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[1]}></PreviewTemplate>
                <PreviewTemplate titlepreview="Template Introduzione" descrizione="Template introduzione Vidà" idTemplate="5" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[1]}></PreviewTemplate>
              </div>)}
          </div>
        </div>
      </React.Fragment >
    );
  }
}
export default ChooseTemplate;
