import React from "react";
import common from "../Component/common.js";
import Loading from "../Component/Loading.js";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import "../Style/sign-up.css";
class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            codice_invito: "",
            check_empty: {
                nome_azienda: false,
                email: false,
                password: false,
                conferma_password: false,
                codice_invito: false
            },
            disabledInput: false,
            disabled_button: false
        }
    }

    inserisciAzienda = async () => {
        this.setState({ loader: true, disabled_button: true });
        const result = await common.eseguiPostRequestNoAuthorization("/sign-up", { nome_azienda: this.state.nome_azienda, email: this.state.email, password: this.state.password, codice_invito: this.state.codice_invito });
        if (result.status === "success") {
            this.setState({ disabledInput: true, success_messagge: "Azienda creata con successo, riceverai una email con le istruzioni per eseguire il primo accesso." });
        }
        else {
            if (result.code) {
                this.setState({ error_messagge: result.code })
            }
        }
        this.setState({ loader: false });
    }
    signUp = () => {
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        //Guardo se nome_azienda è null, vuota o la lunghezza è fuori range 20,4
        if (!this.state.nome_azienda || this.state.nome_azienda.length > 20 || this.state.nome_azienda.length < 4) {
            this.setState({ error_messagge: "Il nome dell'azienda non può contentere più di 15 caratteri o meno di 4", check_empty: { email: false, password: false, conferma_password: false, nome_azienda: true } });
            return;
        }
        //Guardo se l'email è nulla e se rispetta il pattern di una classica email (TODO: la regex non è perfetta)
        if (!this.state.email || !regexEmail.test(this.state.email)) {
            this.setState({ error_messagge: "Inserire una email valida", check_empty: { nome_azienda: false, password: false, conferma_password: false, email: true } });
            return;
        }
        //Guardo se la password e la conferma non siano vuote e che siano uguali
        if ((!this.state.password || !this.state.conferma_password) || (this.state.password.length < 6 || this.state.conferma_password < 6) || (this.state.password !== this.state.conferma_password)) {
            this.setState({ error_messagge: "Le password inserite non corrispondono o la password non è minimo di 6 caratteri con un numero", check_empty: { nome_azienda: false, email: false, password: true, conferma_password: true } })
            return;
        }
        if ((this.state.codice_invito) && (this.state.codice_invito.length < 6)) {
            this.setState({ error_messagge: "Il codice invito sembra non essere corretto!", check_empty: { nome_azienda: false, email: false, password: false, conferma_password: false, codice_invito: true } })
            return;
        }

        this.setState({ error_messagge: "", check_empty: { nome_azienda: false, email: false, password: false, conferma_password: false } });
        this.inserisciAzienda();
    }

    cambiaValore = e => this.setState({ [e.target.name]: e.target.value });

    componentDidMount() {
        document.title = "Registrazione - Bee Dashboard";
        document.body.className = 'body-mobile';
    }

    render() {
        if (this.state.loading) {
            return <Loading />
        }
        else
            return (
                <div className="full-width">
                    <div style={{}} className="centrato-verticale-div-sign-up">
                        {!this.state.disabledInput ?
                            <div>
                                <Link to="/login">
                                    <img src={process.env.PUBLIC_URL + '/bee_logo.png'} style={{ width: "298px", marginBottom: "5%" }}></img>
                                </Link>
                                <p className="sign-up-titolo">Crea il tuo account!</p>
                                <p className="testo-tally-bold-nero">Nome azienda{this.state.check_empty.nome_azienda && <span style={{ color: "red" }}>*</span>}</p>
                                <input
                                    type="text"
                                    onChange={this.cambiaValore}
                                    disabled={this.state.loading || this.state.disabledInput}
                                    className="input-tally"
                                    name="nome_azienda"
                                    placeholder=""
                                ></input>
                                <br></br>
                                <br></br>

                                <p className="testo-tally-bold-nero">E-mail{this.state.check_empty.email && <span style={{ color: "red" }}>*</span>}</p>
                                <input
                                    type="email"
                                    onChange={this.cambiaValore}
                                    disabled={this.state.loading || this.state.disabledInput}
                                    className="input-tally"
                                    name="email"
                                    placeholder=""
                                ></input>
                                <br></br>
                                <br></br>

                                <p className="testo-tally-bold-nero">Password{this.state.check_empty.password && <span style={{ color: "red" }}>*</span>}</p>
                                <input
                                    type="password"
                                    onChange={this.cambiaValore}
                                    disabled={this.state.loading || this.state.disabledInput}
                                    className="input-tally"
                                    name="password"
                                    placeholder=""
                                ></input>
                                <br></br>
                                <br></br>

                                <p className="testo-tally-bold-nero">Conferma password{this.state.check_empty.password && <span style={{ color: "red" }}>*</span>}</p>
                                <input
                                    type="password"
                                    className="input-tally"
                                    disabled={this.state.loading || this.state.disabledInput}
                                    onChange={this.cambiaValore}
                                    name="conferma_password"
                                    placeholder=""
                                ></input>
                                <br></br>
                                <br></br>
                                <p className="testo-tally-bold-nero">Hai un codice sconto?{this.state.check_empty.codice_invito && <span style={{ color: "red" }}>*</span>}inserisci il codice sconto</p>
                                <input
                                    type="text"
                                    className="input-tally"
                                    disabled={this.state.loading || this.state.disabledInput}
                                    onChange={this.cambiaValore}
                                    name="codice_invito"
                                    placeholder=""
                                ></input>
                                <br />
                                <br />
                                <button onClick={this.signUp} disabled={this.state.loading || this.state.disabledInput || this.state.disabled_button} className="button-success-tally">Crea Account</button>

                                <p className="sign-up-error-messagge">
                                    <span>{this.state.error_messagge}</span>
                                </p>
                                {this.state.success_messagge && <p className="sign-up-success-messagge">
                                    <span>{this.state.success_messagge}</span>
                                </p>}

                                <p className="sign-up-condition-tally">
                                    Creando un account dichiari di aver letto e accettato le
                    <a href="#"><span className="sign-up-link-tally"> Condizioni Generali di Fornitura del Servizio</span></a> i
                    <a href="https://www.iubenda.com/privacy-policy/21145215/legal?from_cookie_policy=true&ifr=true&height=800&newmarkup=no"><span className="sign-up-link-tally"> Termini e condizioni</span></a> e
                    <a href="https://www.iubenda.com/privacy-policy/21145215?ifr=true&height=800&newmarkup=no"><span className="sign-up-link-tally"> Politica sulla privacy</span></a> di Tally.
                    </p>
                            </div>
                            :
                            <div className="animated fadeInDown">
                                <img src={process.env.PUBLIC_URL + '/smile-bee.svg'}></img>
                                <br></br>
                                <br></br>
                                <p style={{ fontSize: "1.6em" }}><b>Grazie {this.state.nome_azienda}</b> per aver creato il tuo account!</p>
                                <p>{this.state.success_messagge}</p>
                                <Link to="/login"><button style={{ maxWidth: "450px" }} className="button-success-tally">Accedi</button></Link>
                            </div>
                        }
                    </div>

                </div>
            )
    }
}
export default SignUp;
