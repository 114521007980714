import React from "react";
import common from "../Component/common.js";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import Loading from "../Component/Loading.js";
import "../Style/Login.css";
class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            check: false,
            loader: false,
            resetCompletato: false
        };
        this.cambiaValore = this.cambiaValore.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.resetPasswordCodice = this.resetPasswordCodice.bind(this);
    }
    async resetPassword() {
        this.setState({ loader: true });
        localStorage.setItem('resetEmail', JSON.stringify(this.state.email));
        let result = await common.eseguiPostRequestNoAuthorization(
            "/resetPassword",
            {
                email: this.state.email || "",
            });
        this.setState({ check: true, loader: false });
    }
    async resetPasswordCodice() {
        this.setState({ loader: true });
        let result = await common.eseguiPostRequestNoAuthorization(
            "/resetPasswordCodice",
            {
                codice: this.state.codice || "",
                email: this.state.email || "",
            });
        this.setState({ loader: false, resetCompletato: true });
    }
    componentDidMount() {
        document.title = "Reset Password - Bee Dashboard";
    }
    cambiaValore(event) {
        common.cambiaValoreTesto(this, event);
    }
    render() {
        if (this.state.loader === true) {
            return (
                <Loading error={this.state.errorLogin}></Loading>
            );
        }
        return (
            <div style={{ margin: "0 auto", overflowX: "hidden", overflowY: "hidden", overflow: "hidden" }}>
                {this.state.resetCompletato && <Redirect to="/login"></Redirect>}
                <div style={{}} className="centrato-verticale-div">
                    <Link to="/login"><img
                        src={process.env.PUBLIC_URL + '/bee_logo.png'}
                        style={{ width: "100%", marginBottom: "5%" }}
                    ></img>
                    </Link>
                    {!this.state.check && (
                        <div>
                            <p style={{ fontSize: "0.8em", marginBottom: "6px" }}>Inserisci la tua Email per procedere con il reset della Password.</p>
                            <p className="testo-tally-viola">E-mail</p>
                            <input
                                type="email"
                                onChange={this.cambiaValore}
                                disabled={this.state.loading}
                                className="input-tally"
                                name="email"
                                placeholder="e-mail"
                            ></input>
                            <br></br>
                            <br></br>

                            <button className="btn-tally" onClick={this.resetPassword}>
                                Reset Password
                            </button>
                        </div>
                    )}
                    {this.state.check && (
                        <div>
                            <p style={{ fontSize: "0.8em", marginBottom: "6px" }}>Inserisci il Codice per procedere con il reset della Password.</p>
                            <p className="testo-tally-viola">Codice</p>
                            <input
                                type="codice"
                                onChange={this.cambiaValore}
                                disabled={this.state.loading}
                                className="input-tally"
                                name="codice"
                                placeholder="codice"
                            ></input>
                            <br></br>
                            <br></br>
                            <button className="btn-tally" onClick={this.resetPasswordCodice}>
                                Reset Password
                            </button>
                        </div>
                    )}
                    {this.state.loading && (
                        <img
                            style={{ width: "40px" }}
                            src={process.env.PUBLIC_URL + '/loader.gif'}
                        ></img>
                    )}
                </div>
            </div>
        );
    }
}
export default ResetPassword;
