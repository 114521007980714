import App from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

var config = {
    apiKey: "AIzaSyBShn5zlvasGX6NsDXq93fMvsWKkOmydu0",
    authDomain: "bee-analytics-b711b.firebaseapp.com",
    projectId: "bee-analytics-b711b",
    storageBucket: "bee-analytics-b711b.appspot.com",
    messagingSenderId: "303806207610",
    appId: "1:303806207610:web:e9f9084197f4c178718dc3",
    measurementId: "G-SP60ZNX6S0"
};
App.initializeApp(config);
export default App;
