import React from "react";
import common from "../Component/common.js";
import { BrowserRouter as Router, Redirect, Link } from "react-router-dom";
import "../Style/Login.css";
import Loading from "../Component/Loading.js";
import { Chart } from "react-google-charts";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            errorLogin: false,
            tag: [],
            tagView: [],
            nazioniTag: {}
        };
        this.getTag = this.getTag.bind(this);
    }
    postFetch = async () => {
        let tag = this.state.tag;
        this.setState({ tag: tag, loaded: true });
    }
    async componentDidMount() {
        document.title = "Home - Bee Dashboard";
        if (await this.getTag() && await this.getTotalValueFromTag()) {
            await this.postFetch();
            this.getNazionFromTag();
        }
    }
    getNazionFromTag = () => {
        let nazioni = {};
        let appCountry;
        console.log(this.state.tag);
        this.state.tag?.forEach(tag => {
            tag.CONTINENTE?.address_components?.forEach(comp => {
                if (comp.types[0] === "country") {
                    appCountry = comp.short_name;
                }
            });
            (nazioni[appCountry] > 0 ? nazioni[appCountry]++ : nazioni[appCountry] = 1)
        });
        let data = [["Stato", "Prodotti"]];
        for (const property in nazioni) {
            data = [...data, [property, nazioni[property]]]
        }
        this.setState({ nazioniTag: data });
    }
    async getTag() {
        let result = await common.eseguiPostRequestWithAuthorization("/getTag", {});
        if (result || result.tag) {
            this.setState({ tag: result.tag })
            return true;
        }
        else {
            this.setState({ errorLogin: true })
            return false;
        }
    }
    getTotalValueFromTag = async () => {
        let result = await common.eseguiPostRequestWithAuthorization("/getTotalValueFromTag", {});
        if (!result) {
            this.setState({ errorLogin: true });
            return false;
        } else if (result.status === "success") {
            this.setState({ totaleViews: result.value.totaleViews, totaleProdotti: result.value.totaleProdotti });
            return true;
        }
    }

    calcolaTagAttivi() {
        let totale = 0;
        this.state.tag.forEach(tag => {
            totale = totale + tag.NUMERO_TAG;
        });
        return totale;
    }
    render() {
        if (this.state.loaded !== true) {
            return (
                <Loading></Loading>
            );

        }
        else {
            return (
                <React.Fragment>
                    <div className="page-tally animated fadeInLeft fast">
                        <div className="row" style={{ marginTop: "40px" }}>
                            <div className="col-3 offset-1">
                                <p className="titolo-pagina">Home</p>
                            </div>
                        </div>
                        <div className="row paddingTopPageTally" >
                            <div className="col-2  offset-1">
                                <p className="bold">Panoramica</p>
                                <div className="border-div-tag">
                                    <div className="border-div-tag-inside">
                                        <p className="paragrafo-default-tally">Views totali</p>
                                        <p className="titolo-alto" style={{ fontSize: "1em" }}>{this.state.totaleViews ? common.numberWithCommas(this.state.totaleViews) : "0"}</p>
                                    </div>
                                </div>
                                <div className="border-div-tag">
                                    <div className="border-div-tag-inside">
                                        <p className="paragrafo-default-tally">Progetti Creati</p>
                                        <Link to="view-tag"><p className="titolo-alto" style={{ fontSize: "1em" }}>{this.state.totaleProdotti ? common.numberWithCommas(this.state.totaleProdotti) : ""}</p></Link>
                                    </div>
                                </div>
                            </div>
                            {this.state.tag.length !== 0 && <div className="col-3 offset-1">
                                <div style={{ margin: "0 auto" }}>
                                    <p className="bold">Ultimo Progetto Creato</p>
                                    <div
                                        style={{ border: "1px solid  #707070", borderRadius: "30px", width: "100%", height: "auto", paddingBottom: "15px" }}
                                    >
                                        <div className="row">
                                            <div className="col-4 align-self-center" style={{ paddingLeft: "25px", textAlign: "center", overflowX: "hidden" }}>
                                                <img
                                                    src={this.state.tag[0].URL_IMG_PRODOTTO}
                                                    style={{ width: "100%", margin: "0 auto", maxHeight: "200px", overflowX: "hidden" }}
                                                ></img>

                                            </div>
                                            <div className="col-8">
                                                <div style={{ margin: "15px" }}>
                                                    <div style={{ overflow: "hidden" }}>
                                                        <span style={{ marginTop: "5%", fontWeight: "bold", fontSize: "1.4em", lineHeight: "0.9em" }}>
                                                            {this.state.tag[0] && this.state.tag[0].NOME_PRODOTTO}
                                                        </span>
                                                    </div>
                                                    <ul className="ul-dashboard" style={{ marginTop: "15px", marginBottom: "20px", lineHeight: "1.2em", overflow: "hidden", wordBreak: "break-word" }}>
                                                        <li>{new Date(this.state.tag[0].DATA_CREAZIONE).toLocaleDateString().replace(/\//g, "-")}</li>
                                                        <li>{this.state.tag[0] && this.state.tag[0].STATO_GEOGRAFICO}</li>
                                                        <li>{this.state.tag[0] && this.state.tag[0].CITTA}</li>
                                                        <li>{this.state.tag[0].PUNTO_VENDITA}</li>
                                                    </ul>
                                                    <Link to={"/tag/" + this.state.tag[0].ID_TAG}>
                                                        <button className="btn-tally-show-tag-dati" style={{ fontWeight: "500", border: "1px solid #ff8c00" }}>Dati Statistici</button>
                                                    </Link>
                                                    <Link to={"/create-template/" + this.state.tag[0].ID_TAG}>
                                                        <button className="btn-tally-tag">Modifica</button>
                                                    </Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {
                                this.state.tag.length === 0 &&
                                <div className="col-3 offset-1" style={{textAlign:"center"}}>
                                    <h3 style={{ marginBottom: "0" }}>Benvenuto in <b style={{ color: "#ff8c00" }}>Bee!</b></h3>
                                    <p>Inizia da qui</p>
                                    <Link to="/create-order">
                                        <button style={{ width: "70%",marginRight:"0" }} className="btn-tally-general-green">Nuovo Progetto</button>
                                    </Link>
                                    <br></br>
                                    <br></br>
                                    <Link to="/support">
                                        <button style={{ width: "70%",marginRight:"0" }} className="btn-tally-general">Richiedi supporto</button>
                                    </Link>
                                </div>
                            }
                            <div className="col-3 offset-1">
                                <p className="bold">Dove si trovano i tuoi progetti?</p>
                                <Chart
                                    width={"100%"}
                                    style={{ margin: "0 auto" }}
                                    height={"300px"}
                                    options={{
                                        colors: ["#6FB2FF", "#6FB2FF"],
                                        datalessRegionColor: "#D3D3D3",
                                        legend: "none",
                                        backgroundColor: {
                                            fill: "#fff",
                                            stroke: "#fff",
                                            strokeWidth: "0"
                                        }
                                    }}
                                    chartType="GeoChart"
                                    data={this.state.nazioniTag}
                                    mapsApiKey="AIzaSyCHm8PRZh10O_E9KerkYl2z7hspqh1A8KE"
                                />
                            </div>
                        </div>
                        <div className="row align-self-center" style={{ marginTop: "35px" }}>
                            <div className="col-2 offset-2" style={{ height: "150px", padding: "15px" }}>
                                <Link to="/order-tag">
                                    <div className="box-tally-dashboard d-flex justify-content-center">
                                        <div className="align-self-center">
                                            <img style={{ width: "34px", marginBottom: "7px" }} src="https://dashboard.bee-id.it/assets/icon/account.svg" />
                                            <p><b>Il mio</b> Profilo</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-2" style={{ height: "150px", padding: "15px" }}>
                                <Link to="/support">
                                    <div className="box-tally-dashboard d-flex justify-content-center">
                                        <div className="align-self-center">
                                            <img style={{ width: "34px", marginBottom: "7px" }} src="https://dashboard.bee-id.it/assets/icon/video.svg" />
                                            <p><b>Video</b> Tutorial</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-2" style={{ height: "150px", padding: "15px" }}>
                                <Link to="/support">
                                    <div className="box-tally-dashboard d-flex justify-content-center">
                                        <div className="align-self-center">
                                            <img style={{ width: "34px", marginBottom: "7px", fill: "white" }} src="https://dashboard.bee-id.it/assets/icon/question.svg" />
                                            <p><b>Guida</b> Utente</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-2" style={{ height: "150px", padding: "15px" }}>

                                <a href="https://www.facebook.com/?ref=br_rs" rel="noopener noreferrer" target="_blank"> <div className="box-tally-dashboard d-flex justify-content-center">
                                    <div className="align-self-center">
                                        <img style={{ width: "34px", marginBottom: "7px" }} src="https://dashboard.bee-id.it/assets/icon/news.svg" />
                                        <p><b>News</b></p>
                                    </div>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}
export default Dashboard;
