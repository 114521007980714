import React from "react";
import SendMessage from "../Component/SendMessage.js";
import ReceivedMessage from "../Component/ReceivedMessage.js";

function ChatModal(props) {

    return (
        <div>
            <div className="modal fade show" style={{ padding: "1px" }} id="chat">
                <div className="modal-dialog modal-dialog-centered modal-dialog-support-form" role="document">
                    <div className="modal-content modal-content-support-form ">
                        <div className="modal-header header-modale-chat">
                            <p className="titolo-alto-ticket-support">Ticket</p>
                            <div className="support-ticket" style={{ marginLeft: "20px", marginRight: "6px" }}>
                                {props.richiesta && props.richiesta.STATO === 2 && <div className="support-ticket-stato-2">
                                </div>}
                                {props.richiesta && props.richiesta.STATO === 1 && <div className="support-ticket-stato-1">
                                </div>}
                                {props.richiesta && props.richiesta.STATO === 0 && <div className="support-ticket-stato-0">
                                </div>}
                            </div>
                            <span className="bold" style={{ marginTop: "-3px" }}>{props.richiesta && props.richiesta.TITOLO}</span>
                            <span className="data-header-modal">Creato il {props.richiesta && new Date(props.richiesta.DATA).toLocaleDateString().replace(/\//g, ".")}</span>
                            <img data-dismiss="modal" aria-label="Close" className="close close-support-form" src={"https://dashboard.bee-id.it/assets/icon/closeX.gif"} />
                        </div>
                        <div className="modal-body ">
                            <div className="row">
                                <div className="col-6">
                                    <div className="content-support-chat">
                                        {props.messaggi && props.messaggi.map((messaggio, indice) => {
                                            if (messaggio.RICEVUTO === 0) {
                                                return <SendMessage nomeazienda={props.nomeazienda} key={"message-" + indice} richiesta={props.richiesta} messaggio={messaggio}></SendMessage>
                                            }
                                            else if (messaggio.RICEVUTO === 1) {
                                                return <ReceivedMessage key={"message-" + indice} richiesta={props.richiesta} messaggio={messaggio}></ReceivedMessage>
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div>
                                        <div className="text-area-support-chat">
                                            <span className="bold">Nuovo Messaggio:</span><br></br>
                                            <textarea value={props.textareaMessage} onChange={props.cambiaValoreMessaggio} className="textarea-slum" style={{ border: "0px", boxShadow: "none" }} placeholder="Scrivi qui il tuo messaggio" name="supporto_descrizione"> </textarea>
                                        </div>
                                    </div>
                                    <div >
                                        <button className="btn-tally-supporto-chat-invia" onClick={props.createMessage}>Invia Ticket</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>);
}
export default ChatModal;
