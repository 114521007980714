import React from "react";
import common from "../Component/common.js";

class SupportForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgError: false,
            richiestaCompletata: false
        };
        this.changeValue = this.changeValue.bind(this);
        this.createTicket = this.createTicket.bind(this);
        this.close = this.close.bind(this);
    }

    close(){
        this.setState({imgError: false, richiestaCompletata: false});
    }
    changeValue(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    async createTicket() {
        if(!this.state.titolo && !this.state.testo){
            return;
        }

        let result = await common.eseguiPostRequestWithAuthorization("/createTicket", { titolo: this.state.titolo, testo: this.state.testo });
        if (result && result.status === "success") {
            this.setState({ richiestaCompletata: true, imgError: false, codeError: "" });
        }
        else if (result && result.status === "error") {
            this.setState({ imgError: true, richiestaCompletata: false, codeError: result.code });
        }
        this.setState({ titolo: "", testo: "" })
        setTimeout(async ()=>{await this.props.getRichieste();},2500)
    }

    render() {
        return (
            
                <div className="modal-dialog" style={{height:"405px"}}>

                    {this.state.richiestaCompletata &&
                        <div className="modal-content" style={{border:"none"}}>
                            <div style={{textAlign:"center"}} >
                                <br></br>
                                <img className="error-img-support-form" src={process.env.PUBLIC_URL + '/smile-bee.svg'} />
                                <br></br>
                                <span className="bold">La tua richiesta è stata inviata con successo!</span>
                                <br></br>
                            </div>
                        </div>
                    }

                    {this.state.imgError &&
                        <div className="modal-content"  style={{border:"none"}}>
                            <div style={{textAlign:"center"}}>
                                <br></br>
                                <img className="error-img-support-form" src={process.env.PUBLIC_URL + '/smile-bee.svg'} />
                                <br></br>
                                <span className="bold" style={{}}>Errore!</span>
                                <br></br>
                            </div>
                        </div>
                    }

                    {!this.state.imgError && !this.state.richiestaCompletata &&
                        <div className="modal-content " style={{border:"none"}}>
                            <div >
                                <div className="row">
                                    <div className="col-12" style={{paddingLeft:"25px",paddingRight:"25px"}}>
                                        <span className="bold campi-form-richiesta">Oggetto</span>
                                        <br></br>
                                        <input className="input-support-form" type="email" placeholder="Inserisci l'oggetto del Messaggio" name="titolo" value={this.state.titolo} onChange={this.changeValue}></input>
                                        <span className="bold campi-form-richiesta">Messaggio</span>
                                        <textarea rows="3"
                                            className="textarea-slum textarea-slum-support-form" value={this.state.testo} placeholder="Scrivi qui il tuo messaggio" rows="5" name="testo" onChange={this.changeValue}></textarea>
                                    </div>
                                    <button onClick={this.createTicket} className="btn-tally-supporto btn-tally-supporto-invia-ticket">Invia Ticket</button>
                                   
                                </div>
                            </div>
                        </div>}
                </div>
         


        );
    }
}
export default SupportForm;