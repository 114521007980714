import React from "react";
import common from "../Component/common.js";
import { BrowserRouter as Router, Redirect, Link } from "react-router-dom";
function TagView(props) {
  return (
    <div>
      
      <div className="row view-tag-row" style={{ marginTop: "20px", height: "66px" }}>
        <div className="col-1 offset-1 align-self-center" style={{ textAlign: "center",overflowX:"hidden",overflowY:"hidden" }}>
          <img
            src={props.taginfo.URL_IMG_PRODOTTO}
            style={{height: "60px", width: "auto" }}
          ></img>
        </div>
        <div className="col-1 padding-view-tag" style={{overflow:"hidden"}}>
          <b>{props.taginfo.NOME_PRODOTTO}</b>
        </div>
        <div className="col-1 padding-view-tag">
          <span>{new Date(props.taginfo.DATA_CREAZIONE).toLocaleDateString().replace(/\//g, "-")}</span>
        </div>
        <div className="col-1 padding-view-tag">{props.taginfo.PUNTO_VENDITA || "Nessuno"}</div>
        <div className="col-1 padding-view-tag">
          <span>{props.taginfo.CITTA}</span>
        </div>
        <div className="col-1 padding-view-tag">
          <span> {common.numberWithCommas(props.taginfo.NUMERO_TAG)}</span>
        </div>
        <div className="col-1 padding-view-tag">
          <b style={{ color: "#841d80" }}>{props.taginfo.TOTALE_VIEWS > 0 ? (props.taginfo.TOTALE_VIEWS / props.taginfo.TOTALE_UTENTI).toFixed(2) : 0}</b>
        </div>
        <div className="col-1" style={{ marginTop: "22px" }}>
          <Link to={props.taginfo.STATO === 0 ? "#" : "/create-template/" + props.taginfo.ID_TAG}><button style={{ border: "1px solid #841d80" }} className="btn-tally-show-tag">Modifica</button></Link>
        </div>
        <div className="col-1" style={{ marginTop: "22px" }}>
          <Link to={props.taginfo.STATO === 0 ? "#" : "/tag/" + props.taginfo.ID_TAG}><button style={{ border: "1px solid #ff8c00" }} className="btn-tally-show-tag-dati">Dati</button></Link>
        </div>
        <div className="col-1" style={{ marginTop: "22px" }}>
          <button onClick={()=>{props.cambiaUrlQrCode(props.taginfo.ID_TAG)}} data-toggle="modal" data-target="#exampleModalCenter" className="btn-tally-show-tag-grey">Condividi</button>
        </div>
      </div>
      <div className="row">
        <div className="col-10 offset-1" style={{ border: "1px solid #ADADAD" }}>
        </div>
      </div>

    </div>
  );
}
export default TagView;
