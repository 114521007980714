import React from "react";
import { BrowserRouter as Router, Redirect, Link } from "react-router-dom";
import "../Style/Header.css";
import common from "./common.js";
import i18n from "i18next";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorLogin: false,
      language: i18n.language
    }
    this.checkLogin = this.checkLogin.bind(this);
  }
  async componentDidMount() {
    await this.checkLogin();
    this.setState({ sectionActive: window.location.pathname })
  }
  async checkLogin() {
    let result = await common.eseguiPostRequestWithAuthorization(
      "/checkLogin",
      {}
    );
    if (result == false || result === null || result === undefined || !result.azienda) {
      window.location.href = '/login';
      return false;
    }

    else if (result.status == "success") {
      localStorage.setItem("azienda", JSON.stringify(result.azienda[0]));
      this.setState({ azienda: result.azienda[0] });
      return true;
    }
  }
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("rememberme");
    localStorage.removeItem("azienda");
    window.location.href = '/login';
  }

  changeLanguage = (e) => {
    const lng = e.target.value
    this.setState({ language: lng });
    i18n.changeLanguage(lng);
  }

  render() {
    return (
      <header>
        <div className="row" >
          <div className="col-4 offset-1" style={{ marginTop: "12px" }}>
            <div className="row">
              <div className="col-2">
                <Link to="/order-tag"><img src={this.state.azienda && this.state.azienda.URL_LOGO} className="myimg" /></Link>
              </div>
              <div className="col-8 informazioni-header">
                <span style={{ fontWeight: "bold" }}>{this.state.azienda && this.state.azienda.NOME_AZIENDA}</span>
                <br></br>
                <span>
                  <Link to="/order-tag"><i><span className="link-header" style={{ fontWeight: "300", color: "black", fontSize: "0.8em", textDecoration: "underline" }} >Profilo</span></i></Link>
                  <span style={{ fontWeight: "300", fontSize: "0.8em" }}> /</span>
                  <i><span className="link-header" style={{ fontWeight: "300", fontSize: "0.8em", cursor: "pointer", textDecoration: "underline" }} onClick={this.logout}>Log-out</span></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-6" style={{ marginTop: "12px", textAlign: "right" }}>
            <Link to="/dashboard"><button onClick={() => { this.setState({ sectionActive: "/dashboard" }) }} style={{ width: "15%" }} className={this.state.sectionActive === "/dashboard" ? "btn-tally-general-active" : "btn-tally-general"}>Home</button></Link>
            <Link to="/view-tag"><button onClick={() => { this.setState({ sectionActive: "/view-tag" }) }} style={{ width: "15%" }} className={this.state.sectionActive === "/view-tag" ? "btn-tally-general-active" : "btn-tally-general"}>Progetti</button></Link>
            <Link to="/support"><button onClick={() => { this.setState({ sectionActive: "/support" }) }} style={{ width: "15%" }} className={this.state.sectionActive === "/support" ? "btn-tally-general-active" : "btn-tally-general"}>Supporto</button></Link>
            <Link to="/create-order"><button onClick={() => { this.setState({ sectionActive: "/create-order" }) }} style={{ marginRight: "0", width: "25%" }} className={this.state.sectionActive === "/create-order" ? "btn-tally-general-green-active" : "btn-tally-general-green"}>Nuovo Progetto
            </button></Link>
            {/*<select value={this.state.language} onChange={this.changeLanguage}>
              <option value="it">it</option>
              <option value="es">es</option>
              <option value="en">en</option>
            </select>*/}
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
