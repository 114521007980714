import React, { useState } from "react";
import { BrowserRouter as Router, Redirect, Link } from "react-router-dom";
function PreviewTemplate(props) {
    const [checkoverpreview, setCheckoverpreview] = useState(false);
    return (
        <div className="col-3" style={{ marginBottom: "80px" }}>
            <div className="preview-template-div" onMouseOver={() => setCheckoverpreview(true)} onMouseOut={() => setCheckoverpreview(false)} data-toggle="modal" data-target={"#modalpreviewTemplate" + props.idTemplate}>
                <div className={!checkoverpreview ? "mobile-background-preview" : "mobile-background-preview-active"} style={{ paddingLeft: "0px" }}>
                    <div style={{ textAlign: "center" }} className={checkoverpreview ? " overlay" : "overlay-active"} >
                        <div style={{ textAlign: "center" }} className={checkoverpreview ? "text fade-in-top" : undefined} >
                            <span className="title-preview">{props.titlepreview}</span><br />
                            <span className="preview-view-btn">
                                Visualizza
                            </span></div>
                    </div>
                    <img
                        className={checkoverpreview ? "image-over" : "image-out"}
                        style={{ borderRadius: "10px", width: "95%", height: "100%", objectFit: "contain", marginTop: "-10px" }}
                        src={props.srcImg}
                    ></img>
                </div>
                <p style={{ fontSize: "1.4em", textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>{props.titlepreview}</p>
                <div id={"modalpreviewTemplate" + props.idTemplate} className="modal fade " role="dialog">
                    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "1200px" }}>
                        <div className="modal-content modal-preview" style={{ border: "1px solid #ff8c00" }}>
                            <div className="modal-header" style={{ border: "0" }}>
                                <img data-dismiss="modal" style={{ cursor: "pointer" }} className="close" src={"https://dashboard.bee-id.it/assets/icon/closeX.gif"} />
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-5 offset-1">
                                        <p className="modal-title-preview">{props.titlepreview}</p>
                                        <span className="descrizione-tally">
                                            {props.descrizione}
                                        </span>
                                        <br></br>
                                        <br></br>
                                        {props.caratteristiche && <span>
                                            <b>Caratteristiche principali</b><br></br>
                                            <span className="descrizione-tally">{props.caratteristiche}</span>
                                        </span>}

                                        <button data-dismiss="modal" style={{ marginTop: "25px" }} onClick={() => setTimeout(() => {
                                            document.getElementById(`choose-id-${props.idTag}-${props.idTemplate}`).click()
                                        }, 200)} className="button-success-tally">Scegli questo Design</button>
                                        <Link id={`choose-id-${props.idTag}-${props.idTemplate}`} to={`/create-template/${props.idTag}/${props.idTemplate}`} disabled={!props.idTag}>
                                        </Link>
                                    </div>
                                    <div className="col-5 offset-1">
                                        <div className="mobile-background-preview" style={{ margin: "0 auto", width: "60%", paddingLeft: "0px", paddingBottom: "15px" }}>
                                            <div className="overlay-active" >
                                                <div className="text-not" ><span>{props.titlepreview}</span><br></br><span className="preview-view-btn">Visualizza</span></div>
                                            </div>
                                            <img
                                                className={"image-out"}
                                                style={{ borderRadius: "10px", width: "95%", height: "auto", objectFit: "cover" }}
                                                src={props.srcImg}
                                            ></img>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ border: "0" }}>
                            </div>
                        </div>

                    </div>
                </div>
            </div >
        </div >

    );
}
export default PreviewTemplate;