import React from "react";
import common from "../Component/common.js";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import Loading from "../Component/Loading.js";
import "../Style/Login.css";
import { SketchPicker } from 'react-color';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      twoAutenticator: false,
      loginEseguito: false,
      loading: false,
      codeError: false,
      secret: "6LdcKb8UAAAAABIW3mJbZAnr-QpAvaVZ8j_eCsO1",
      loader: false
    };
    this.cambiaValore = this.cambiaValore.bind(this);
    this.eseguiLogin = this.eseguiLogin.bind(this);
    this.calcolaViewTotali = this.calcolaViewTotali.bind(this);
    this.verificaCodice = this.verificaCodice.bind(this);
  }
  async componentDidMount() {
    document.title = "Login - Bee Dashboard";
  }
  cambiaValore(event) {
    common.cambiaValoreTesto(this, event);
  }
  async verificaCodice() {
    let result = await common.eseguiPostRequestNoAuthorization(
      "/verificaCodice",
      {
        codice: this.state.codice,
        email: localStorage.getItem("email"),
        password: localStorage.getItem("password")
      }
    );
    if (
      result &&
      result.rememberme &&
      result.token &&
      result.stato === "success"
    ) {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      localStorage.setItem("rememberme", result.rememberme);
      localStorage.setItem("token", result.token);
      this.setState({ loader: true })
      this.calcolaViewTotali();
    }
    if (result && result.stato === "error") {
      this.setState({ codeError: true });
    }
  }
  async calcolaViewTotali() {
    let result = await common.eseguiPostRequestWithAuthorization("/insertTotalValue", {});
    if (result && result.status === "success") {
      this.setState({ loader: false })
      this.setState({ loginEseguito: true });
    }
  }
  caricaDashboard = () => {
    let port = "";
    (window.location.port || window.location.port != 80 || window.location.port !== 443) ? port = ":" + window.location.port : port = "";
    window.location.replace(window.location.protocol + "//" + window.location.hostname + port);
  }
  eseguiLogin() {
    this.setState({
      loading: true
    });
    let rememberme = localStorage.getItem("rememberme") || "";

    /*if(this.state.tokenGoogle){
        return;
    }*/
    var params = {
      email: this.state.email,
      password: this.state.password,
      rememberme: rememberme
    };
    fetch(process.env.REACT_APP_BACKEND + "/login", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token"),
        credentials: "include"
      },
      body: JSON.stringify(params)
    })
      .then(res => res.json())
      .then(
        result => {
          if (result.stato === "verify") {
            this.setState({ twoAutenticator: true });
            localStorage.setItem("email", this.state.email);
            localStorage.setItem("password", this.state.password);
          }
          if (result.stato === "success") {
            localStorage.setItem("token", result.token);
            this.setState({ loader: true })
            this.calcolaViewTotali();
          }
          if (result.stato === "error") {
            this.setState({ loginError: true });
            localStorage.removeItem("rememberme");
          }
          if (result.stato === "error-token") {
            localStorage.removeItem("rememberme");
          }
          this.setState({
            loading: false
          });
        },
        error => {
          this.setState({
            loading: false,
            error
          });
        }
      );
  }
  render() {
    if (this.state.loader === true) {
      return (
        <Loading text={{ __html: '"Non importa che ti muovi piano,<br></br> l&rsquo;importante è che non ti fermi."' }} sottoTesto={"Confucio"} error={this.state.errorLogin}></Loading>
      );
    }
    return (
      <div style={{ margin: "0 auto", overflowX: "hidden", overflowY: "hidden", overflow: "hidden" }}>
        {this.state.loginEseguito && this.caricaDashboard()}
        {!this.state.twoAutenticator && (
          <div style={{}} className="centrato-verticale-div">
            <img
              src={process.env.PUBLIC_URL + '/bee_logo.png'}
              style={{ width: "100%", marginBottom: "5%" }}
            ></img>
            <p className="testo-tally-viola">E-mail</p>
            <input
              type="email"
              onChange={this.cambiaValore}
              disabled={this.state.loading}
              className="input-tally"
              name="email"
              placeholder="e-mail"
            ></input>
            <br></br>
            <br></br>
            <p className="testo-tally-viola">Password</p>
            <input
              type="password"
              className="input-tally"
              disabled={this.state.loading}
              onChange={this.cambiaValore}
              name="password"
              placeholder="password"
            ></input>
            <br></br>
            <br></br>
            <button className="btn-tally" onClick={this.eseguiLogin}>
              Accedi
            </button>
            <Link to="/reset-password">
              <p style={{ marginTop: "10px", marginLeft: "15px2gScF8" }} className="testo-tally-grigio">
                Password dimenticata?
              </p>
            </Link>
            {this.state.loading && (
              <img
                style={{ width: "40px" }}
                src={process.env.PUBLIC_URL + '/loader.gif'}
              ></img>
            )}
            {this.state.loginError && (
              <p
                style={{ color: "red", fontSize: "0.8em", marginLeft: "15px" }}
              >
                Errore email o password sbagliate
              </p>
            )}
          </div>
        )}
        {this.state.twoAutenticator && (
          <div style={{}} className="centrato-verticale-div">
            <img
              src={process.env.PUBLIC_URL + '/bee_logo.png'}
              style={{ width: "100%", marginBottom: "5%" }}
            ></img>
            <br></br>
            <p style={{ lineHeight: "1em", color: "#707070" }}>
              E' stata inviata una email contenente<br></br> il codice di
              verifica al tuo indirizzo.
            </p>
            <p className="testo-tally-viola">Verifica a due fattori</p>
            <input
              type="text"
              onChange={this.cambiaValore}
              disabled={this.state.loading}
              className="input-tally"
              name="codice"
              placeholder="Inserisci il codice"
            ></input>
            <br></br>
            <br></br>
            <br></br>
            <button className="btn-tally" onClick={this.verificaCodice}>
              Verifica
            </button>
            {this.state.loading && (
              <img
                style={{ width: "40px" }}
                src={process.env.PUBLIC_URL + '/loader.gif'}
              ></img>
            )}
            {this.state.codeError && (
              <p
                style={{
                  color: "red",
                  fontSize: "0.8em",
                  marginLeft: "15px",
                  marginTop: "10px"
                }}
              >
                Codice errato
              </p>
            )}
          </div>
        )}
      </div>
    );
  }
}
export default Login;
